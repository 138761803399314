import { ContentItem } from '@/components';
import { type ContentItemInterface, ContentItemUITemplateKeys } from '@/interfaces';
import WithImage from '../WithImage';

interface ContentItemsProps {
  image?: {
    contentType?: string;
    url: string;
    description: string;
  };
  data: ContentItemInterface[];
}

const ContentItems: React.FC<ContentItemsProps> = ({ image, data }) => {
  const hasMetric = data && data.some((contentItem) => contentItem.uiTemplate === ContentItemUITemplateKeys.Metrics);
  const hasService = data && data.some((contentItem) => contentItem.uiTemplate === ContentItemUITemplateKeys.Services);

  return (
    <WithImage image={hasService ? undefined : image} hasMetric={!!hasMetric}>
      {data.map((item, index) => (
        <ContentItem key={index} {...item} wrapperImage={image} />
      ))}
    </WithImage>
  );
};

export default ContentItems;
